import React from "react"
import { Col, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import Form from "../components/Forms/GeneralContact"
import BlogReel from "../components/BlogReel/BlogReel"

const description = `The Residences at Biltmore provide 24 hour concierge service for our hotel guests and are managed by Southern Resort Group. Let us manage your Asheville condo investment.`

const contentRowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    marginTop: 32,
    maxWidth: 1360,
  },
}

const formRowProps = {
  align: "top",
  gutter: [12, 24],
  justify: "start",
  type: "flex",
  style: { marginTop: 32 },
}

const fullWidthColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

const contentColProps = {
  xs: 24,
  md: 22,
  lg: 12,
  xl: 14,
}

const formColProps = {
  xs: 24,
  md: 22,
  lg: 12,
  xl: 10,
}

const formProps = id => {
  return {
    formName: "General Contact",
    automationId: id,
    gtmClickId: "about-page-form-button",
  }
}

export default ({ data, location }) => {
  const { hero } = data

  const heroProps = {
    contentfulAsset: hero,
    height: `35vh`,
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Asheville Property Management - The Residences at Biltmore"
        url={location.href}
      />
      <Hero {...heroProps} />
      <Row {...contentRowProps}>
        <Col {...fullWidthColProps}>
          <Row {...formRowProps}>
            <Col span={24}>
              <Typography.Title>Property Management</Typography.Title>
              <Typography.Paragraph>
                At{" "}
                <a href="https://www.southernresortgroup.com/">
                  Southern Resort Group
                </a>{" "}
                we take care of the details; advertising, sales, payments,
                taxes, housekeeping, maintenance, repairs, and everything in
                between.
              </Typography.Paragraph>
              <br />
              <br />
              <Typography.Title leel={2}>
                Condo Rental Management Services:
              </Typography.Title>
            </Col>
            <Col {...contentColProps}>
              <Typography.Paragraph>
                <ul>
                  <li>Call Center & Online Booking 24 hours a day</li>
                  <li>
                    Internet Marketing & SEO, referral web traffic, PPC
                    strategies
                  </li>
                  <li>
                    Database curation, email marketing, direct marketing
                    strategies that engage guests and generate repeat business
                  </li>
                  <li>
                    Local event promotion and hosting to drive occupancy rates
                  </li>
                  <li>
                    Reservation system, inventory, and channel management
                    (including Online Travel Agencies)
                  </li>
                  <li>Historical data-driven revenue optimization</li>
                  <li>
                    Housekeeping, discovering and scheduling repairs,
                    coordinating routine/annual maintenance
                  </li>
                  <li>Accounting & bookkeeping</li>
                  <li>
                    Detailed accounting and revenue reports for every condo
                    owner
                  </li>
                </ul>
              </Typography.Paragraph>
            </Col>
            <Col {...formColProps}>
              <Form {...formProps(38)} />
            </Col>
            <Col span={24}>
              <Typography.Paragraph>
                For more information regarding the Residences at Biltmore rental
                program please call 828-237-7911 or submit the web form.
              </Typography.Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
      <BlogReel numPosts={3} />
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "RAB - onebedroomlivingroom" }) {
      ...HeroFluid
    }
  }
`
