import React from "react"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Row, Col, Alert, Spin } from "antd"
import PropTypes from "prop-types"
import { antdHelpers } from "@avldev/gatsby-active-campaign-forms"
import { fieldNames, formUrl } from "../../../config/forms"

import { Name, Email, Phone, Message, Submit } from "./Fields"

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const formColProps = {
  xs: 20,
}

class ContactForm extends React.Component {
  formRef = React.createRef(null)

  static propTypes = {
    buttonIcon: PropTypes.string,
    formName: PropTypes.string.isRequired,
    automationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    gtmClickId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }

  static defaultProps = {
    buttonIcon: "check",
    formName: "General Contact",
    automationId: "37",
    gtmClickId: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      buttonIcon: props.icon,
      formSuccess: false,
      formName: props.formName,
      automationId: props.automationId,
      gtmClickId: props.gtmClickId,
      name: "",
    }
  }

  componentDidMount() {
    this.props.form.validateFields()
  }

  formSubmitted = () => {
    this.setState({ formSuccess: true })
  }

  handleSubmit = event => {
    event.preventDefault()

    this.props.form.validateFields(async (err, values) => {
      if (err) return

      const { current: form } = this.formRef
      if (!form) return

      const onSubmitStart = () => {
        this.setState({
          buttonIcon: "loading",
          name: values.name.split(` `)[0],
        })
      }

      const onSubmitEnd = status => {
        this.setState({ formSuccess: status === 200 })
      }

      const onSubmitSuccess = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: `generic_event`,
            event_category: `Contact`,
            event_action: `Submit`,
            event_label: `Contact Page`,
          })
        }
      }

      antdHelpers.onSubmit(
        form,
        { onSubmitStart, onSubmitSuccess, onSubmitEnd },
        process.env.NODE_ENV
      )
    })
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form
    const nameError = isFieldTouched("name") && getFieldError("name")
    const emailError = isFieldTouched("email") && getFieldError("email")
    const messageError = isFieldTouched("message") && getFieldError("message")

    return (
      <>
        {!this.state.formSuccess ? (
          <Spin spinning={this.state.buttonIcon === "loading" ? true : false}>
            <form
              action={formUrl}
              className="content-contact-form"
              onSubmit={this.handleSubmit}
              ref={this.formRef}
              style={{ textAlign: `left`, width: `100%` }}
            >
              <Row type="flex" align="top" justify="center">
                <Col {...formColProps}>
                  <input type="hidden" name="u" value="18" data-name="u" />
                  <input type="hidden" name="f" value="18" data-name="f" />
                  <input type="hidden" name="s" data-name="s" />
                  <input type="hidden" name="c" value="0" data-name="c" />
                  <input type="hidden" name="m" value="0" data-name="m" />
                  <input type="hidden" name="act" value="sub" data-name="act" />
                  <input type="hidden" name="v" value="2" data-name="v" />
                  <input
                    type="hidden"
                    name="or"
                    value="b74cb0700ba688e2155da02671214ad7"
                    data-name="or"
                  />
                  <Name decorator={getFieldDecorator} validator={nameError} />
                  <Email decorator={getFieldDecorator} validator={emailError} />
                  <Phone decorator={getFieldDecorator} />
                  <Message
                    decorator={getFieldDecorator}
                    name={fieldNames.message}
                    placeholder="How can we help you?"
                    required={true}
                    rows={5}
                    validator={messageError}
                  />
                  <Submit
                    id={this.state.gtmClickId}
                    icon={this.state.buttonIcon}
                    disabled={hasErrors(getFieldsError())}
                  />
                </Col>
              </Row>
            </form>
          </Spin>
        ) : (
          <Alert
            message={`Thanks, ${this.state.name}!`}
            description="We have received your message and will get back to you as soon as possible."
            type="success"
            showIcon
          />
        )}
      </>
    )
  }
}

const GeneralContactForm = Form.create({ name: "general_contact_form" })(
  ContactForm
)

export default GeneralContactForm
